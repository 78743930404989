.title {
  max-width: fit-content;
  position: relative;
  font-size: 34px;
  font-weight: 900;
  margin-right: auto !important;
  margin-left: auto !important;
  
  @media (max-width: 48em) {
    font-size: 24px;
  }
}
  
.description {
  margin: auto;

  font-weight: 900;
}

.card {
  border: 1px solid light-dark(var(--mantine-color-gray-1), var(--mantine-color-dark-5));
}