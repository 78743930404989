.header {
  background-color: #fafafa;
  box-shadow: 0px 1px 11px 0px rgb(0 0 0/15%);
  padding-bottom: 10px;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 100;
}

.link {
  display: block;
  line-height: 1;
  border-radius: var(--mantine-radius-sm);
  text-decoration: none;
  color: light-dark(var(--mantine-color-gray-7), var(--mantine-color-dark-0));
  font-size: var(--mantine-font-size-md);
  font-weight: bold !important;

  &:hover {
    color: #48a172;
  }

  [data-mantine-color-scheme] &[data-active] {
      color: #48a172;
  }
}

.linkSmall {
  display: block !important;
  line-height: 1 !important;
  border-top: 1px solid #eee !important;
  padding: 15px 20px !important;
  width: 100% !important;

  text-decoration: none;
  color: light-dark(var(--mantine-color-gray-7), var(--mantine-color-dark-0)) !important;
  font-size: var(--mantine-font-size-sm) !important;
  font-weight: 600 !important;

  &:hover {
    color: #48a172 !important;
  }

  [data-mantine-color-scheme] &[data-active] {
    color: #48a172 !important;
  }
}


.inputSearch input:focus {
  border-color: #48a172 !important
}