.title {
    max-width: fit-content;
    position: relative;
    font-size: 34px;
    font-weight: 900;
    margin-right: auto !important;
    margin-left: auto !important;
    
    @media (max-width: 48em) {
        font-size: 24px;
    }
}
    
.description {
    margin: auto;
    font-weight: 900;
}

.card {
    height: auto;
    background-size: cover;
    background-position: center;
}
  
.contentCard {
    position: absolute;
    inset: 0;
    padding: var(--mantine-spacing-xl);
    z-index: 1;
    flex-direction: column !important;
}
  
.titleCard {
    color: white !important;
    margin-bottom: calc(var(--mantine-spacing-xs) / 2) !important;
    font-size: 36px !important;
    @media (max-width: 36em) {
        font-size: 28px !important;
    }
}

.btnSpoiler {
    width: 100%;
    
    :hover {
        text-decoration: none !important;
    }
}

.btnSpoiler:hover > button {
    text-decoration: none !important;
}